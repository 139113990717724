import Vue from 'vue';
import BotaoWhatsapp from '../../botao-whatsapp/components/BotaoWhatsapp';
const botoes = document.getElementsByClassName('payt-button-whatsapp');

for (let botao of botoes){
    createVueBotaoWhatsapp(botao);
}

function createVueBotaoWhatsapp(elemento){
    if(!elemento){
        return;
    }
    
    new Vue({
        el: elemento,
        components: { BotaoWhatsapp },
        mounted: function(){
            
        }
        
    });
}