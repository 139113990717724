<template>
<div :class="$props.whatsPosicao" class="payt-whats-fixed-btn resizable-font">
    <div v-if="$props.capturarDados == 'S'">
        <div class="close-botao-ws" v-if="showcontent" @click="showcontent = !showcontent">
            x
        </div>
        <div @click="showcontent = !showcontent" class="text-center bloco-ws-title"><i class="fa-brands fa-whatsapp" style="margin-right: 5px;"></i>{{$props.textoBotao}}</div>
        <div :class="{'bloco-ws-msg-open': showcontent, 'bloco-ws-msg-close': !showcontent}" ref="wsContent" class="bloco-ws-msg">
            <div class="balao-msg">
                {{$props.textoChamada}}
            </div>
            <div class="bloco-ws-contato">
                <label for="nome" class="mt-3 mb-1">Nome:</label>
                <div class="format-ws-input">
                    <input type="text" @blur="disabledButton"  name="nome" v-model="contato.nome" placeholder="Inserir o nome">
                </div>
                <label for="email" class="mt-3 mb-1">Seu e-mail:</label>
                <div class="format-ws-input">
                    <input type="email" @blur="disabledButton"  name="email" v-model="contato.email" placeholder="Inserir e-mail">
                </div>
                <label for="telefone" class="mt-3 mb-1">Número de contato:</label>
                <div  class="format-ws-input">
                    <input type="tel" name=""  @blur="disabledButton" @input="contato.telefone = contato.telefone.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');" v-model="contato.telefone" id="field_tel_new_store_desktop" style="color: #363636 !important;">
                    <small id="phoneInternationalErrorNewStoreDesktop" class="help-block" style="color:red; display:none;">Número inválido</small>
                    <input type="hidden" name="cliente_ddi" id="telefone_ddi_new_store_desktop" v-model="contato.ddi"/>
                </div>
                <div @click="enviarContato()" class="button-ws mt-3">
                    <span style="color: #FFF;">Iniciar conversa</span>
                </div>
            </div>
        </div>
    </div>
    <div v-if="$props.capturarDados == 'N'" @click="enviarContato()" class="text-center bloco-ws-title"><i class="fa-brands fa-whatsapp" style="margin-right: 5px;"></i>{{$props.textoBotao}}</div>
</div>
</template>
<script>
    export default {
        props:[
            'textoBotao',
            'textoChamada',
            'whatsNumero',
            'textoWhatsappCliente',
            'whatsPosicao',
            'capturarDados'
        ],
        data() {
            return {
                contato: {
                    nome: null,
                    email: null,
                    telefone: null,
                    ddi: null,
                },
                showcontent: false,
            }
        },
        methods:{
            disabledButton(){
                for(let key of Object.keys(this.contato)){
                    if(this.contato['nome'] && this.contato['email'] && this.contato['telefone']){
                        $('.button-ws').removeClass('disabledbutton');
                    }else{
                        $('.button-ws').addClass('disabledbutton');
                    }  
                }
            },
            updateDDI(){
                const input = document.querySelector('#field_tel_new_store_desktop');
                const iti = window.intlTelInputGlobals.getInstance(input);
                const ddi = iti.getSelectedCountryData().dialCode;

                const inputDDI = document.querySelector("#telefone_ddi_new_store_desktop");
                inputDDI.value = ddi;
                this.contato.ddi = ddi;
            },
            validarTelefone(){
                const input = document.querySelector('#field_tel_new_store_desktop');
                const iti = window.intlTelInputGlobals.getInstance(input);

                if(iti.isValidNumber()) { // Válido
                    input.style.borderColor = '#3c763d';
                    document.querySelector('#phoneInternationalErrorNewStoreDesktop').style.display = "none";
                    iti.setNumber(iti.getNumber()); // Aplicando formatação
                    return true;
                } else { // Inválido
                    input.style.borderColor = '#F00';
                    document.querySelector('#phoneInternationalErrorNewStoreDesktop').style.display = "block";
                    return false;
                } 
            },
            enviarContato(){
                this.$emit('iniciarConversaWs', this.contato);
                this.contato.nome   = null;
                this.contato.email  = null;
                this.contato.telefone  = null;

                this.showcontent    = false;
            }
        },
        mounted(){
            window.addEventListener('load', ()=> {
                $('.button-ws').addClass('disabledbutton');
                const input = document.querySelector('#field_tel_new_store_desktop');
            
                window.intlTelInput(input, {
                    utilsScript:"/assets/lojas/plugins/international-telephone-input/js/utils.js",
                    separateDialCode: true
                });

                const iti = window.intlTelInputGlobals.getInstance(input);
                iti.setCountry("br");

                this.updateDDI();
                input.addEventListener("countrychange", this.updateDDI);
                input.addEventListener("change", this.validarTelefone);
            });
        }
    }
</script>