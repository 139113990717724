<template>
<div>
  <botao-whatsapp-mobile
    v-if="mobile"
    :texto-botao="$props.textoBotao"
    :texto-chamada="$props.textoChamada"
    :whats-numero="$props.whatsNumero"
    :texto-whatsapp-cliente="$props.textoWhatsappCliente"
    @iniciarConversaWs="iniciarConversa" />
  <botao-whatsapp-desktop
    v-if="desktop"
    :texto-botao="$props.textoBotao"
    :texto-chamada="$props.textoChamada"
    :whats-numero="$props.whatsNumero"
    :texto-whatsapp-cliente="$props.textoWhatsappCliente"
    :whats-posicao="$props.whatsPosicao"
    :capturar-dados="$props.capturarDados"
    @iniciarConversaWs="iniciarConversa" />
</div>
</template>

<script>
import BotaoWhatsappMobile from './BotaoWhatsappMobile.vue';
import BotaoWhatsappDesktop from './BotaoWhatsappDesktop.vue';
import axios from 'axios';
export default {
    components: { BotaoWhatsappMobile, BotaoWhatsappDesktop },
    props:{
        textoBotao: {
            type: String,
            default: () => '',
        },
        textoChamada:{
            type: String,
            default: () => '',
        },
        whatsNumero:{
            type: [Number, String],
            default: null,
        },
        textoWhatsappCliente:{
            type: String,
            default: () => '',
        },
        url:{
            type: String,
            default: () => '',
        },
        whatsPosicao:{
            type: String,
            default: () => ''
        },
        capturarDados:{
            type: String,
            default: () => ''
        },
    },
    data() {
        return{
            mobile: false,
            desktop: false,
        };
    },
    updated(){
        window.iniciarConversa = this.iniciarConversa;
        if(window.innerWidth <= 768){
            this.showWsMobile();
        }else{
            this.showWsdesktop();
        }
    },
    mounted(){
        window.iniciarConversa = this.iniciarConversa;
        if(window.innerWidth <= 768){
            this.showWsMobile();
        }else{
            this.showWsdesktop();
        }
    },
    methods:{
        iniciarConversa(contato){
            let params = new URLSearchParams();
            params.append('nome', contato.nome);
            params.append('telefone', contato.telefone);
            params.append('email', contato.email);
            params.append('ddi', contato.ddi);

            axios.post(this.$props.url, params);
            
            const url  = 'https://wa.me/' + this.$props.whatsNumero + `?text=${this.$props.textoWhatsappCliente}`;
            window.open(url, '_blank').focus();
        },
        showWsMobile(){
            this.mobile = true;
        },
        showWsdesktop(){
            this.desktop = true;
        }
    }
};
</script>
