var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "bloco-ws-mobile" }, [
    _c(
      "div",
      {
        staticClass: "payt-whats-fixed-btn-mobile-new-store",
        class: {
          visibilityMobile: !_vm.isCloseOpen,
          invisibleMobile: _vm.isCloseOpen,
        },
      },
      [
        _c("div", { staticClass: "bloco-ws-title-mobile-new-store" }, [
          _c(
            "div",
            { staticClass: "row", staticStyle: { color: "#FFF !important" } },
            [
              _c(
                "div",
                {
                  staticClass: "col-2 text-end",
                  on: {
                    click: function ($event) {
                      _vm.closeOpenMobile()
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa-regular fa-square-caret-down",
                    staticStyle: { "font-size": "20px" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-10" }, [
                _c("i", {
                  staticClass: "fa-brands fa-whatsapp",
                  staticStyle: { "margin-right": "5px", "font-size": "18px" },
                }),
                _vm._v(
                  "\n          " + _vm._s(_vm.$props.textoBotao) + "\n        "
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { ref: "wsContent", staticClass: "bloco-ws-msg-mobile-new-store" },
          [
            _c("div", { staticClass: "balao-msg-mobile-new-store" }, [
              _vm._v(
                "\n        " + _vm._s(_vm.$props.textoChamada) + "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "bloco-ws-contato-mobile-new-store" }, [
              _c(
                "label",
                { staticClass: "mt-3 mb-1", attrs: { for: "nome" } },
                [_vm._v("Nome:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "format-ws-input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.contato.nome,
                      expression: "contato.nome",
                    },
                  ],
                  attrs: {
                    type: "text",
                    name: "nome",
                    placeholder: "Inserir o nome",
                  },
                  domProps: { value: _vm.contato.nome },
                  on: {
                    blur: _vm.disabledButton,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.contato, "nome", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "mt-3 mb-1", attrs: { for: "email" } },
                [_vm._v("Seu e-mail:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "format-ws-input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.contato.email,
                      expression: "contato.email",
                    },
                  ],
                  attrs: {
                    type: "email",
                    name: "email",
                    placeholder: "Inserir e-mail",
                  },
                  domProps: { value: _vm.contato.email },
                  on: {
                    blur: _vm.disabledButton,
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.contato, "email", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "label",
                { staticClass: "mt-3 mb-1", attrs: { for: "telefone" } },
                [_vm._v("Número de contato:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "format-ws-input" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.contato.telefone,
                      expression: "contato.telefone",
                    },
                  ],
                  ref: "field_tel_new_store_mobile",
                  staticStyle: { color: "#363636 !important" },
                  attrs: { type: "tel", name: "" },
                  domProps: { value: _vm.contato.telefone },
                  on: {
                    blur: _vm.disabledButton,
                    input: [
                      function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.contato, "telefone", $event.target.value)
                      },
                      function ($event) {
                        _vm.contato.telefone = _vm.contato.telefone
                          .replace(/[^0-9.]/g, "")
                          .replace(/(\..*?)\..*/g, "$1")
                      },
                    ],
                  },
                }),
                _vm._v(" "),
                _c(
                  "small",
                  {
                    staticClass: "help-block",
                    staticStyle: { color: "red", display: "none" },
                    attrs: { id: "phoneInternationalErrorNewStoreMobile" },
                  },
                  [_vm._v("Número inválido")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.contato.ddi,
                      expression: "contato.ddi",
                    },
                  ],
                  ref: "telefone_ddi_new_store_mobile",
                  attrs: { type: "hidden", name: "cliente_ddi" },
                  domProps: { value: _vm.contato.ddi },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.contato, "ddi", $event.target.value)
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "button-ws-mobile-new-store",
                  staticStyle: { "margin-top": "15%" },
                  on: {
                    click: function ($event) {
                      return _vm.enviarContato()
                    },
                  },
                },
                [
                  _c("span", { staticStyle: { color: "#FFF !important" } }, [
                    _vm._v("Iniciar conversa"),
                  ]),
                ]
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }