<template>
<div class="bloco-ws-mobile">
  <div
    class="payt-whats-fixed-btn-mobile-new-store"
    :class="{'visibilityMobile': !isCloseOpen, 'invisibleMobile': isCloseOpen }">
    <div class="bloco-ws-title-mobile-new-store">
      <div
        class="row"
        style="color: #FFF !important;">
        <div
          class="col-2 text-end"
          @click="closeOpenMobile ()">
          <i
            class="fa-regular fa-square-caret-down"
            style="font-size: 20px;" />
        </div>
        <div class="col-10">
          <i
            class="fa-brands fa-whatsapp"
            style="margin-right: 5px; font-size: 18px;" />
          {{ $props.textoBotao }}
        </div>
      </div>
    </div>
    <div
      ref="wsContent"
      class="bloco-ws-msg-mobile-new-store">
      <div class="balao-msg-mobile-new-store">
        {{ $props.textoChamada }}
      </div>
      <div class="bloco-ws-contato-mobile-new-store">
        <label
          for="nome"
          class="mt-3 mb-1">Nome:</label>
        <div class="format-ws-input">
          <input
            v-model="contato.nome"
            type="text"
            name="nome"
            placeholder="Inserir o nome"
            @blur="disabledButton">
        </div>
        <label
          for="email"
          class="mt-3 mb-1">Seu e-mail:</label>
        <div class="format-ws-input">
          <input
            v-model="contato.email"
            type="email"
            name="email"
            placeholder="Inserir e-mail"
            @blur="disabledButton">
        </div>
        <label
          for="telefone"
          class="mt-3 mb-1">Número de contato:</label>
        <div class="format-ws-input">
          <input
            ref="field_tel_new_store_mobile"
            v-model="contato.telefone"
            type="tel"
            name=""
            style="color: #363636 !important;"
            @blur="disabledButton"
            @input="contato.telefone = contato.telefone.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');">
          <small
            id="phoneInternationalErrorNewStoreMobile"
            class="help-block"
            style="color:red; display:none;">Número inválido</small>
          <input
            ref="telefone_ddi_new_store_mobile"
            v-model="contato.ddi"
            type="hidden"
            name="cliente_ddi">
        </div>
        <div
          class="button-ws-mobile-new-store"
          style="margin-top: 15%;"
          @click="enviarContato()">
          <span style="color: #FFF !important;">Iniciar conversa</span>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
    props:[
        'textoBotao',
        'textoChamada',
        'whatsNumero',
        'textoWhatsappCliente',
        'showWhasapp'
    ],
    data() {
        return {
            contato: {
                nome: null,
                email: null,
                telefone: null,
                ddi: null,
            },
            isCloseOpen: true,
            isDisablad: true,
        };
    },
    mounted(){
        window.addEventListener('load', ()=> {
            $('.button-ws-mobile-new-store').addClass('disabledbutton');
            const input = this.$refs.field_tel_new_store_mobile;
        
            window.intlTelInput(input, {
                utilsScript:'/assets/lojas/plugins/international-telephone-input/js/utils.js',
                separateDialCode: true
            });

            const iti = window.intlTelInputGlobals.getInstance(input);
            iti.setCountry('br');

            this.updateDDI();
            input.addEventListener('countrychange', this.updateDDI);
            input.addEventListener('change', this.validarTelefone);
        });
        
        window.toggleContatoWhatsappMobile = this.closeOpenMobile;

    },
    methods:{
        disabledButton(){
            for(let key of Object.keys(this.contato)){
                if(this.contato['nome'] && this.contato['email'] && this.contato['telefone']){
                    $('.button-ws-mobile-new-store').removeClass('disabledbutton');
                }else{
                    $('.button-ws-mobile-new-store').addClass('disabledbutton');
                }  
            }
        },
        updateDDI(){
            const input = this.$refs.field_tel_new_store_mobile;
            const iti = window.intlTelInputGlobals.getInstance(input);
            const ddi = iti.getSelectedCountryData().dialCode;

            const inputDDI = this.$refs.telefone_ddi_new_store_mobile;
            inputDDI.value = ddi;
            this.contato.ddi = ddi;
        },
        validarTelefone(){
            const input = this.$refs.field_tel_new_store_mobile;
            const iti = window.intlTelInputGlobals.getInstance(input);

            if(iti.isValidNumber()) { // Válido
                input.style.borderColor = '#3c763d';
                document.querySelector('#phoneInternationalErrorNewStoreMobile').style.display = 'none';
                iti.setNumber(iti.getNumber()); // Aplicando formatação
                return true;
            } else { // Inválido
                input.style.borderColor = '#F00';
                document.querySelector('#phoneInternationalErrorNewStoreMobile').style.display = 'block';
                return false;
            } 
        },
        enviarContato(){
            this.$emit('iniciarConversaWs', this.contato);
            this.contato.nome   = null;
            this.contato.email  = null;
            this.contato.telefone = null;

            this.closeOpenMobile();
        },
        closeOpenMobile(){
            this.isCloseOpen = !this.isCloseOpen;
        }
    }
};
</script>