var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.mobile
        ? _c("botao-whatsapp-mobile", {
            attrs: {
              "texto-botao": _vm.$props.textoBotao,
              "texto-chamada": _vm.$props.textoChamada,
              "whats-numero": _vm.$props.whatsNumero,
              "texto-whatsapp-cliente": _vm.$props.textoWhatsappCliente,
            },
            on: { iniciarConversaWs: _vm.iniciarConversa },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.desktop
        ? _c("botao-whatsapp-desktop", {
            attrs: {
              "texto-botao": _vm.$props.textoBotao,
              "texto-chamada": _vm.$props.textoChamada,
              "whats-numero": _vm.$props.whatsNumero,
              "texto-whatsapp-cliente": _vm.$props.textoWhatsappCliente,
              "whats-posicao": _vm.$props.whatsPosicao,
              "capturar-dados": _vm.$props.capturarDados,
            },
            on: { iniciarConversaWs: _vm.iniciarConversa },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }